.Projects {
  background-color: rgb(239, 238, 236);
}

.Projects-body {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  align-content: center;
  align-items: center;
  margin: 0 1em;
}

.Projects-chandelier {
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  gap: 20px;
}

.Projects-chandelier-img {
  height: auto;
  width: auto;
  max-width: 300px;
  margin: auto;
}

.Projects-blurb {
  margin: 0 5vw 0 5vw;
  padding-top: 50px;
  padding-bottom: 5em;
  font-family: 'avenir', sans-serif;
  text-align: justify;
}

@media screen and (max-width: 700px) {
  .Projects-blurb {
    margin-left: 2vw;
    max-width: 70vw;
  }

  .Projects-hide {
    display: none;
  }
}